<template>
  <div class="sidebar bg-bar-color" :data="backgroundColor" :style="{background:this.$global.globalSidebarBgColor}">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <div style="width:100%">
          <a href="#" class="simple-text logo-normal text-center">
            <!--<img :src="logo" alt="app-logo" />-->
            <img id="companylogosidebar" style="max-width:125px" :src="this.$global.globalCompanyPhoto" alt="app-logo" />
          </a>
          <div id="sidebarCompanyName" class="simple-text logo-normal text-center text-bar-color" :style="{color:this.$global.globalTextColor}" style="word-wrap: break-word;white-space: break-spaces;">
           {{ this.$global.globalCompanyName }}
          </div>
          <small class="company-select-tag text-bar-color" v-if="this.$global.settingMenuShow && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected" :style="{color:this.$global.globalTextColor}" style="display:inline-block;width:100%;text-align:center;font-size:100%;padding-top:5px;font-size:12px">View By Client Name :</small>
          <div v-if="this.$global.settingMenuShow && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected" style="text-align:center" class="pt-2">
            <select style="font-size:12px;width:80%" v-model="selectedGroupCompany" v-on:change="onGroupChange($event)">
              <option  v-for="(item, index) in this.$global.selectsGroupCompany.companyGroupList" :key="index" :value="item.id">{{ item.group_name }}</option>
            </select>
            <!--<i class="fas fa-plus pl-2" style="color:#FFF"></i>-->
          </div>
        </div>
      </div>
      <slot></slot>
      <ul class="nav sidebar-item-wrapper text-bar-color" :style="{color:this.$global.globalTextColor}">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Uncommon Reach',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'UR',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/img/icon-ur.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'red',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  data() {
    return {
      selectedGroupCompany: '',
    };
  },
  methods: {
    onGroupChange: function onGroupChange(event) {
      //alert(this.$global.selectsGroupCompany.companyGroupSelected);
      localStorage.setItem('companyGroupSelected',event.target.value);
      this.$router.go();
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  mounted() {
    if(this.$store.state.userData.company_logo != null && this.$store.state.userData.company_logo != '') {
        document.getElementById('companylogosidebar').src = this.$store.state.userData.company_logo
    }else{
        if (this.$store.state.userData.user_type == 'client' && this.$store.state.userData.companyparentlogo != null && this.$store.state.userData.companyparentlogo != '') {
           document.getElementById('companylogosidebar').src = this.$store.state.userData.companyparentlogo;
        }else{
          document.getElementById('companylogosidebar').src = '/img/logoplaceholder.png'
        }
    }
    
    this.selectedGroupCompany = '';
    if (localStorage.getItem('companyGroupSelected') != null) {
       this.selectedGroupCompany = localStorage.getItem('companyGroupSelected');
    }
    //console.log(this.$global.selectsGroupCompany.companyGroupList);
    if (this.$global.selectsGroupCompany.companyGroupList == null || this.$global.selectsGroupCompany.companyGroupList.length == 0) {
      const userData = this.$store.getters.userData;
      /** GET COMPANY GROUP */
      this.$store.dispatch('GetCompanyGroup', {
        companyID: userData.company_id,
        userModule: 'LeadsPeek',
      }).then(response => {
          //console.log(response.length);
          if (response.result == 'success') {
            this.$global.selectsGroupCompany.companyGroupList = response.params;
            this.$global.selectsGroupCompany.companyGroupList.unshift({'id':'','group_name':'View All'});
            
            if (localStorage.getItem('companyGroupSelected') == null && response.params.length > 1) {
              localStorage.setItem('companyGroupSelected',response.params[1].id);
              this.selectedGroupCompany = response.params[1].id;
            }
          }
      },error => {
          
      });
      /** GET COMPANY GROUP */
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
