<template>
  <li v-bind:id="'rowList_'+ node.company_id" class="node-tree">
    <div class="tree-column text-left">
      <!--<a href="javascript:void(0)" class="list-drag draggable"><i class="fa-solid fa-bars"></i></a>-->
      <div class="company-name-column d-flex">
        <a style="color: var(--text-primary-color)" v-show="node.user_type == 'userdownline'" v-bind:href="'#rowDownLine_'+ node.company_id" data-toggle="collapse"><i class="fas fa-chevron-square-down fa-lg pr-2"></i></a>
        <div :id="'companyname' + node.id">{{ node.company_name }} {{this.statusdownline(node.user_type,node.package_id)}}</div>
      </div>
      <div class="tools text-left">
        {{ node.name}}
      </div>
      <div class="email-column text-left">
        {{ node.email }}
      </div>
     
      <div class="col-action text-left">
        <!--<a class="action" v-if="node.children && node.children.length > 0" href="javascript:void(0);" @click="showDownline(node.company_id)"><i class="fas fa-chevron-down" style="color:white"></i></a>-->
         <el-tooltip
          content="Manual Bill"
          effect="light"
          :open-delay="300"
          placement="top"
          v-if="this.$global.menuUserType != 'sales' && node.user_type != 'client'"
        >
        <base-switch
          v-model="chkManualBill"
          on-text="ON"
          off-text="OFF"
          style="margin-top:10px;margin-right:10px"
          :activeNode="node"
          @manual-bill="manualBillTrigger"
        ></base-switch>
        </el-tooltip>
        <el-tooltip
          content="Set as free plan"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <span class="action cursor-pointer" v-if="node.user_type == 'userdownline'"><i class="fad fa-coin" @click="handleFreeSet(node)"></i></span>
        </el-tooltip>
        <el-tooltip
          content="Set Sales Person"
          effect="light"
          :open-delay="300"
          placement="top"
          v-if="this.$global.systemUser && this.$global.menuUserType != 'sales'"
        >
          <a class="action" href="javascript:void(0);" v-if="node.user_type == 'userdownline'"><i class="fas fa-user-headset" v-bind:id="'iconsalesperson_'+ node.company_id"  :style="[isBestSales?{'color':'orange'}:{'color':'gray'}]" @click="handleSalesSet(node,index)"></i></a>
        </el-tooltip>
        <el-tooltip
          content="Set cost for agency"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <a class="action" href="javascript:void(0);" v-if="node.user_type == 'userdownline'"><i class="fa-solid fa-dollar-sign" style="color:green" @click="handlePriceSet(node)"></i></a>
        </el-tooltip>
        <el-tooltip
          content="View as agency / client"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <span  class="action cursor-pointer" href="javascript:void(0);"><i class="fas fas fa-eye"  @click="handleSwitchUser(node)"></i></span>
        </el-tooltip>
        <!--<a class="action" href="javascript:void(0);" v-if="node.user_type == 'userdownline'"><i class="fas fas fa-user-plus" style="color:white" @click="AddEditDownline(node.company_id,'newclient')"></i></a>-->
        <el-tooltip
          content="Edit Agency / Client"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <a class="action" href="javascript:void(0);"><i class="tim-icons icon-pencil" style="color:green" @click="AddEditDownline(node.company_id,'editdownline',node)"></i></a>
        </el-tooltip>
        <el-tooltip
          content="Remove Agency / Client"
          effect="light"
          :open-delay="300"
          placement="top"
          v-if="this.$global.menuUserType != 'sales'"
        >
          <a v-if="this.$global.settingMenuShow_delete" class="action" href="javascript:void(0);"><i class="tim-icons icon-simple-remove" style="color:red" @click="handleDelete(node)"></i></a>
        </el-tooltip>
      </div>
      <div v-bind:id="'salesperson_'+ node.company_id" class="sales-column text-left" style="line-height: 30px !important; margin-top: 10px; margin-bottom: 10px;">
        <div v-if="node.salesrep">
          <i class="fas fa-user pr-2"></i>{{ node.salesrep }}
        </div>
        <div v-if="node.accountexecutive">
          <i class="fas fa-user-headset pr-2"></i>{{ node.accountexecutive }}
        </div>
        <div v-if="node.accountref">
          <i class="fas fa-user-tag pr-2"></i>{{ node.accountref }}
        </div>
      </div>
      <div class="col-created text-left">
        {{ node.created_at }}
      </div>
    </div>
    <!--EDIT DOWNLINE -->
    <ValidationObserver :ref="'frmAddDownline' + node.company_id" v-bind:id="'showEditDownline'+ node.company_id" class="row collapse" style="line-height: 1.5 !important;">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <div class="col-sm-6 col-md-6 col-lg-6 form-group has-label">
                          <ValidationProvider
                              name="Company Name"
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                          >
                            <base-input
                                label="Company Name*"
                                type="text"
                                placeholder="Input Company Name"
                                addon-left-icon="fas fa-building"
                                v-model="node.company_name"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                            >
                            </base-input>
                          </ValidationProvider>
                        </div>
                         <div class="col-sm-6 col-md-6 col-lg-6">
                          <ValidationProvider
                              name="Full Name"
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                          >
                            <base-input
                                label="Full Name*"
                                type="text"
                                placeholder="Input Client Full Name"
                                addon-left-icon="far fa-id-badge"
                                v-model="node.name"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                          </ValidationProvider>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                                name="Email"
                                rules="required|email"
                                v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                                label="Email*"
                                type="email"
                                placeholder="Input Client Email"
                                addon-left-icon="fas fa-envelope"
                                v-model="node.email"
                                :lowercase="true"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                            </ValidationProvider>
                          </div>

                           <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                                name="Phone Number"
                                rules="required"
                                v-slot="{ passed, failed, errors }"
                            >
                            <label>Phone Number*</label>

                            <VuePhoneNumberInput id='number-downline-input' :showLabel="false" :error="failed" :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate" />
                            </ValidationProvider>
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label" v-if="false">
                            <base-input
                                label="Domain Name:"
                                type="text"
                                placeholder="yourdomain.com"
                                addon-left-icon="fas fa-globe-americas"
                                v-model="node.domain"
                                >
                            </base-input>
                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3 form-group has-label" v-if="node.user_type == 'userdownline'">
                            <ValidationProvider
                                name="Subdomain"
                                :rules="{required:true,regex:/^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/}"
                                v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                                label="Subdomain:"
                                type="text"
                                placeholder="yoursubdomain"
                                addon-left-icon="fas fa-globe-americas"
                                v-model="node.subdomain"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                             </ValidationProvider>
                          </div>
                          <div class="col-sm-9 col-md-5 col-lg-5 ml-0 pl-0" style="padding:32px" v-if="node.user_type == 'userdownline'">
                            .{{rootDomain}}
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6 form-group has-label" v-if="node.user_type == 'userdownline'">
                          <span class="" style="font-size: 0.80143rem;">Payment Term :</span>
                            <div class="row pl-3">
                                <base-checkbox
                                v-for="term in node.rootpaymentterm"
                                :key="term.value"
                                class="mr-3"
                                v-model="selectedterms[term.value]"
                                >
                                {{ term.label }}
                              </base-checkbox>
                            </div>
                          </div>
                          <div class="col-6 form-group has-label" v-if="node.user_type == 'userdownline'">
                            <span class="" style="font-size: 0.80143rem;">White Label Your Domain :</span>
                            <div class="row pl-3">
                                <base-checkbox v-model="ClientWhiteLabeling">White Labelling</base-checkbox>
                            </div>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label" v-if="false">
                            <base-input
                                label="Organization ID :"
                                type="text"
                                placeholder="Input Organization ID"
                                addon-left-icon="fas fa-passport"
                                v-model="node.simplifi_organizationid"
                                >
                            </base-input>
                          </div>

                          <div class="col-sm-8 col-md-8 col-lg-8 form-group has-label">
                            &nbsp;
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-button v-if="this.$global.settingMenuShow_update" size="sm" class="pull-right" :id="'btnSave' + node.id" style="height:40px" @click="ProcessAddEditDownline(node,'editdownline')">
                                Save
                              </base-button>
                              <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditDownline(node.company_id,'editdownline')">
                                Cancel
                              </base-button>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </ValidationObserver>
    <!--EDIT DOWNLINE -->
    <!--ADD DOWNLINE -->
    <div v-bind:id="'showAddDownline'+ node.company_id" class="row collapse">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                            <base-input
                                label="Company Name"
                                type="text"
                                placeholder="Input Company Name"
                                addon-left-icon="fas fa-building"
                                v-model="ClientCompanyName"
                                >
                            </base-input>
                        </div>
                         <div class="col-sm-3 col-md-3 col-lg-3">
                            <base-input
                                label="Full Name"
                                type="text"
                                placeholder="Input Client Full Name"
                                addon-left-icon="far fa-id-badge"
                                v-model="ClientFullName"
                                >
                            </base-input>
                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3">
                            <base-input
                                label="Email"
                                type="email"
                                placeholder="Input Client Email"
                                addon-left-icon="fas fa-envelope"
                                v-model="ClientEmail"
                                >
                            </base-input>
                          </div>

                           <div class="col-sm-3 col-md-3 col-lg-3">
                            <!-- <base-input
                                label="Phone Number"
                                type="text"
                                placeholder="Input Client Phone"
                                addon-left-icon="fas fa-phone-alt"
                                v-model="ClientPhone"
                                >
                            </base-input> -->
                            <label>Phone Number*</label>
                            <VuePhoneNumberInput :noExample="true"  :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate"  :validateTrigger="triggerPhonenumberValidation"/>
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <base-input
                                label="Domain Name:"
                                type="text"
                                placeholder="yourdomain.com"
                                addon-left-icon="fas fa-globe-americas"
                                v-model="DownlineDomain"
                                >
                            </base-input>
                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                            <base-input
                                label="Subdomain:"
                                type="text"
                                placeholder="yoursubdomain"
                                addon-left-icon="fas fa-globe-americas"
                                v-model="DownlineSubDomain"
                                >
                            </base-input>
                            
                          </div>
                          <div class="col-sm-5 col-md-5 col-lg-5 ml-0 pl-0" style="padding:32px">
                            .exactmatchmarketing.com
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <base-input
                                label="Organization ID :"
                                type="text"
                                placeholder="Input Organization ID"
                                addon-left-icon="fas fa-passport"
                                v-model="DownlineOrganizationID"
                                >
                            </base-input>
                          </div>

                          <div class="col-sm-8 col-md-8 col-lg-8 form-group has-label">
                            &nbsp;
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-button v-if="this.$global.settingMenuShow_create" size="sm" class="pull-right" :id="'btnNewSave' + node.id" style="height:40px" @click="ProcessAddEditDownline(node,'newdownline')">
                                Save
                              </base-button>
                              <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditDownline(node.company_id,'newdownline')">
                                Cancel
                              </base-button>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </div>
    <!--ADD DOWNLINE -->
    <ol v-if="node.children && node.children.length" v-bind:class="{ 'collapse' : node.children && node.children.length}" v-bind:id="'rowDownLine_'+ node.company_id">
      <node v-for="child in node.children" :node="child" v-bind:key="child.id" :GetDownlineList="GetDownlineList"></node>
    </ol>

  </li>
</template>

<script>
import swal from 'sweetalert2';
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
import { BaseSwitch } from '@/components/';
extend("email", email);
extend("required", required);

export default {
  name: "node",
  props: {
    node: {},
    index: {},
    rootDomain: '',
    mypackages: {},
    isBestSales: {},
    GetDownlineList: {
      type: Function,
    }
  },
  data() {
    return {
      manualBill: this.node.manual_bill === 'T',
      ClientCompanyName: '',
      ClientFullName: '',
      ClientEmail: '',
      ClientPhone: '',
      ClientWhiteLabeling: false,
      clientPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
      },
      DownlineDomain: '',
      DownlineSubDomain: '',
      DownlineOrganizationID: '',
      isPhoneNumberValid: false,
      triggerPhonenumberValidation :false,
      rootpaymentterm:[],
      selectedterms:{},
    }
  },
  computed: {
    chkManualBill: {
      get() {
        return this.manualBill;
      },
      set(value) {
        if (value) {
          this.node.manual_bill = 'T';
        } else {
          this.node.manual_bill = 'F';
        }
      }
    }
  },
  methods:{
    manualBillTrigger(data) {

      if (data.status == false) {
        swal.fire({
                  title: "All campaigns will now be billed the wholesale cost of leads, directly billed to the Agency's card on record. All billing from the Agency to the Client must be handled separately from this system.",
                  text: 'Are you sure want to do this?',
                  icon: '',
                  showCancelButton: true,
                  customClass: {
                  confirmButton: 'btn btn-fill mr-3',
                  cancelButton: 'btn btn-danger btn-fill'
                  },
                  confirmButtonText: "Yes, I am sure.",
                  buttonsStyling: false
          }).then(result => {
                  if (result.value) {
                    /** SET AGENCY TO MANUAL BILL / DIRECT BILL */
                    this.$store.dispatch('agencyManualBill', {
                        companyID: data.row.company_id,
                        manualBill: 'T',
                    }).then(response => {
                        this.manualBill = true;
                         this.$notify({
                              type: 'success',
                              message: 'Your settings have been saved.',
                              icon: 'tim-icons icon-bell-55'
                          });  

                    },error => {
                        this.manualBill = false;
                        this.$notify({
                              type: 'warning',
                              message: 'We are unable to save. Please try again later or contact support for assistance.',
                              icon: 'tim-icons icon-bell-55'
                          });  
                    });

                    /** SET AGENCY TO MANUAL BILL / DIRECT BILL */
              
                  }else{
                    this.manualBill = false;
                  }
          });
      }else{

        swal.fire({
                  title: "All campaigns will be stopped and a to-date billing for all campaigns will be charged to the Agency's card on record. Campaigns may only be restarted after the Agency has re-established their Stripe account and then Client has an active Credit Card in their profile.",
                  text: 'Are you sure want to do this?',
                  icon: '',
                  showCancelButton: true,
                  customClass: {
                  confirmButton: 'btn btn-fill mr-3',
                  cancelButton: 'btn btn-danger btn-fill'
                  },
                  confirmButtonText: "Yes, I am sure.",
                  buttonsStyling: false
          }).then(result => {
                  if (result.value) {
                    /** SET AGENCY TO MANUAL BILL / DIRECT BILL */
                    this.$store.dispatch('agencyManualBill', {
                        companyID: data.row.company_id,
                        manualBill: 'F',
                    }).then(response => {
                        this.manualBill = false;
                         this.$notify({
                              type: 'success',
                              message: 'Your settings have been saved.',
                              icon: 'tim-icons icon-bell-55'
                          });  

                    },error => {
                        this.manualBill = true;
                        this.$notify({
                              type: 'warning',
                              message: 'We are unable to save. Please try again later or contact support for assistance.',
                              icon: 'tim-icons icon-bell-55'
                          });  
                    });

                    /** SET AGENCY TO MANUAL BILL / DIRECT BILL */
                  }else{
                    this.manualBill = true;
                  }
          });
          

      }
    },
    onPhoneUpdate(payload){
           this.isPhoneNumberValid = payload.isValid
           this.clientPhoneNumber.countryCode = payload.countryCode
           this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
    },
    resetPhone(){
            this.clientPhoneNumber={
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            }
    },
    statusdownline(user_type,package_id) {

      if (user_type == 'userdownline') {
        
        //if (process.env.VUE_APP_DEVMODE == 'true') {
          if (package_id == this.mypackages.nonwhitelabelling.monthly) {
            return '(NWM)';
          }else if (package_id == this.mypackages.nonwhitelabelling.yearly) {
            return '(NWY)';
          }else if (package_id == this.mypackages.whitelabeling.monthly) {
            return '(WM)';
          }else if (package_id == this.mypackages.whitelabeling.yearly) {
            return '(WY)';
          }else if (package_id == this.mypackages.freeplan) {
            return '(FREE)';
          }
        // }else{
        //   if (package_id == 'price_1MBWmhCm8XcQag44eGhk0k3Z') {
        //     return '(NWM)';
        //   }else if (package_id == 'price_1MBWmhCm8XcQag44hRNP5Oeh') {
        //     return '(NWY)';
        //   }else if (package_id == 'price_1MBWnOCm8XcQag44bOCL5PCc') {
        //     return '(WM)';
        //   }else if (package_id == 'price_1MBWnOCm8XcQag44N2sSQu5I') {
        //     return '(WY)';
        //   }else if (package_id == 'price_1MBWo7Cm8XcQag44lhijmoNW') {
        //     return '(FREE)';
        //   }
        // }

      }
    },
    handleFreeSet(row) {
      //console.log(row);
      swal.fire({
                title: 'This will create a Free Plan for the Agency',
                text: 'Are you sure want to do this?',
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: "Yes, Set as a Free Plan",
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                  /** SET AGENCY TO FREE PLAN */
                  this.$store.dispatch('setAgencyFreePlan', {
                      CompanyID: row.company_id,
                      packageID: row.package_id,
                  }).then(response => {
                      //console.log(response)
                      row.package_id = response.packageid;
                      $('#companyname' + row.id).html(row.company_name + ' (FREE)');

                      this.$notify({
                            type: 'success',
                            message: 'Your plan has been updated',
                            icon: 'tim-icons icon-bell-55'
                        });  

                  },error => {
                      this.$notify({
                            type: 'warning',
                            message: 'We are unable to save your plan, please try again later or contact the support',
                            icon: 'tim-icons icon-bell-55'
                        });  
                  });
                  /** SET AGENCY TO FREE PLAN */
                }
        });
    },
    handlePriceSet(row) {
      this.$emit('clickPriceSet', row);
    },
    handleSalesSet(row,index) {
      this.$emit('clickSalesSet',row,index);
    }, 
    ClearDownlineForm() {
      this.ClientCompanyName = '';
      this.ClientFullName = '';
      this.ClientEmail = '';
      this.ClientPhone = '';
      this.DownlineDomain = '';
      this.DownlineSubDomain = '';
      this.DownlineOrganizationID = '';
      this.resetPhone()
    },
    ProcessAddEditDownline(node,act) {
      if (act == 'newdownline') {
        if(this.ClientFullName != '' && this.ClientEmail != '') {
          $('#btnNewSave' + node.id).attr('disabled',true);
          $('#btnNewSave' + node.id).html('Processing...');  
          //console.log(node);
           /** CREATE CLIENT */
          this.$store.dispatch('CreateClient', {
              companyID: node.company_id,
              idsys: this.$global.idsys,
              userType:'userdownline',
              ClientCompanyName: this.ClientCompanyName,
              ClientFullName: this.ClientFullName,
              ClientEmail: this.ClientEmail,
              ClientPhone: this.clientPhoneNumber.number,
              ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
              ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
              ClientWhiteLabeling: this.ClientWhiteLabeling ? 'T' : 'F',
              DownlineDomain: this.DownlineDomain,
              DownlineSubDomain: this.DownlineSubDomain,
              DownlineOrganizationID: this.DownlineOrganizationID,
          }).then(response => {
              //console.log(node.children);
              //console.log(response[0]);
              if (response.result == 'success') {
                if (typeof(node.children) == 'undefined') {
                  this.$set(node,"children",[]);
                }
                node.children.push(response.data[0]);
                //this.node.push(response[0])
                $('#showAddDownline' + node.company_id).collapse('hide');
                $('#btnNewSave' + node.id).attr('disabled',false);
                $('#btnNewSave' + node.id).html('Save'); 
                this.GetDownlineList()
                this.$notify({
                    type: 'success',
                    message: 'Data has been added successfully',
                    icon: 'far fa-save'
                });  
              }else{
                $('#btnNewSave' + node.id).attr('disabled',false);
                $('#btnNewSave' + node.id).html('Save'); 
                this.GetDownlineList()
                this.$notify({
                    type: 'primary',
                    message: response.message,
                    icon: 'fas fa-bug'
                }); 
              }
          },error => {
              $('#btnNewSave' + node.id).attr('disabled',false);
              $('#btnNewSave' + node.id).html('Save'); 

              this.$notify({
                  type: 'primary',
                  message: 'Server might be busy please try again later',
                  icon: 'fas fa-bug'
              }); 
          });
         
          /** CREATE CLIENT */
        }
      }else{
        if(!this.isPhoneNumberValid){
        this.$notify({
          type: 'primary',
          message: 'Please enter valid phone number',
          icon: 'fas fa-bug'
      })
        return
      }
        var frmName = 'frmAddDownline' + node.company_id;
        this.$refs[frmName].validate().then(res => {
            if (!res) {
                return false;
            }else{
              const isValidSelectedTerms = Object.values(this.selectedterms).some(value => value === true);

              if(!isValidSelectedTerms && node.user_type == 'userdownline'){
                this.$notify({
                type: 'danger',
                message: 'Please select payment term',
                });
                return false
              }
              
                /** PROCESS EDIT DOWNLINE */
                if ((node.name != '' && node.email != '') && (typeof node.name != 'undefined' && typeof node.email != 'undefined')) {
                    $('#btnSave' + node.id).attr('disabled',true);
                    $('#btnSave' + node.id).html('Processing...');
                    
                    let selectedTermsArray = [];
                    
                    if(node.user_type == 'userdownline'){
                        node.rootpaymentterm.forEach(term => {
                            if (!(term.value in this.selectedterms)) {
                                this.$set(this.selectedterms, term.value, false);
                            }
                        });
                        for (let key in this.selectedterms) {
                              selectedTermsArray.push({
                                  term: key,
                                  status: this.selectedterms[key]
                              });
                        }
                    }

                    const conditionWhiteLabeling = () => {
                      if(node.user_type == 'userdownline'){
                        if(this.ClientWhiteLabeling){
                          return 'T'
                        } else {
                          return 'F'
                        }
                      } else if (node.user_type == 'client') {
                        return null
                      } else {
                        return null
                      }
                    } 

                    /** UPDATE CLIENT */
                      this.$store.dispatch('UpdateClient', {
                          companyID: node.company_id,
                          idsys: this.$global.idsys,
                          ClientID: node.id,
                          ClientCompanyName: node.company_name,
                          ClientFullName: node.name,
                          ClientEmail: node.email,
                          ClientPhone: this.clientPhoneNumber.number,
                          ClientWhiteLabeling: conditionWhiteLabeling(),
                          ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
                          ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
                          DownlineDomain: node.domain,
                          DownlineSubDomain: node.subdomain,
                          DownlineOrganizationID: node.simplifi_organizationid,
                          selectedterms: selectedTermsArray,
                          action: 'downline',
                      }).then(response => {
                                //console.log(response[0]);
                                $('#showEditDownline' + node.company_id).collapse('hide');
                                $('#btnSave' + node.id).attr('disabled',false);
                                $('#btnSave' + node.id).html('Save'); 
                                this.$emit('refresh-list')
                                this.$notify({
                                  type: 'success',
                                  message: 'Data has been updated successfully',
                                  icon: 'far fa-save'
                                });  
                                this.GetDownlineList('created_at', 'descending')
                          },error => {
                              $('#btnSave' + node.id).attr('disabled',false);
                              $('#btnSave' + node.id).html('Save'); 
                              this.$notify({
                                  type: 'primary',
                                  message: 'Server might be busy please try again later',
                                  icon: 'fas fa-bug'
                              });   
                          });
                            /** UPDATE CLIENT */
                  }
                  /** PROCESS EDIT DOWNLINE */
            }
        });

      }
      
    },
    handleSwitchUser(row) {
      swal.fire({
                title: 'Switch as this user.',
                text: `Are you sure want to switch to this user?`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, switch user',
                buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('switchUserData', {
                usrID: row.id,
            }).then(response => {
              if (response == 'success') {
                if(row.subdomain) {
                  localStorage.setItem('subdomainAgency', `${row.orisubdomain}`);
                  localStorage.setItem('rootcomp', this.$global.rootcomp);
                }
                if (row.profile_setup_completed == 'T' && ((row.status_acc == 'completed' && row.user_type == 'userdownline') ||(row.status_acc == '' && row.user_type == 'client'))) {
                  var usrData = this.$store.getters.userData; 
                  if (typeof(usrData.leadlocalurl) != "undefined" && usrData.leadlocalurl != "") {
                    window.document.location = '/' + usrData.leadlocalurl + '/dashboard';
                  }else if (typeof(usrData.leadlocatorurl) != "undefined" && usrData.leadlocatorurl != "") {
                    window.document.location = '/' + usrData.leadlocatorurl + '/dashboard';
                  }else{
                    window.document.location = '/configuration/general-setting';
                  }
                }else if (row.status_acc != 'completed') {
                  window.document.location = '/configuration/general-setting';
                }else {
                  window.document.location = "/user/profile-setup";
                }
              }
            });
          }
          
        });
    },
    handleDelete(row) {
            //console.log('Row: ' + index);
            swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE USER */
                    
                        this.$store.dispatch('RemoveClient', {
                            companyID: row.company_id,
                            userID: row.id,
                        }).then(response => {
                            //console.log(response)
                            if(response.data.result == "success") {
                              $('#rowList_' + row.company_id).remove();
                              swal.fire({
                                  title: 'Deleted!',
                                  text: `You deleted ${row.name}`,
                                  icon: 'success',
                                  confirmButtonClass: 'btn btn-success btn-fill',
                                  buttonsStyling: false
                              });
                            }else{
                                this.$notify({
                                    type: 'primary',
                                    message: response.data.message,
                                    icon: 'fas fa-bug'
                                }); 
                            }
                        },error => {
                            
                        });
                        
                     /** REMOVE USER */
                }
        });
    },
    /*showDownline(id) {
      $('#rowDownLine_' + id).collapse('toggle');
    },*/
    AddEditDownline(id,act,row) {
      // $('.phonefield input').usPhoneFormat({
      //     format: 'xxx-xxx-xxxx',
      // });
      this.ClientWhiteLabeling = row.is_whitelabeling == 'T' ? true : false
      this.clientPhoneNumber.number = row.phonenum
      this.clientPhoneNumber.countryCode = row.phone_country_code
      this.clientPhoneNumber.countryCallingCode = row.phone_country_calling_code
      if(this.clientPhoneNumber.number){
         this.triggerPhonenumberValidation = !this.triggerPhonenumberValidation
      }
      let responsePaymentTerm = row.selected_payment_term

      let result = {}
      if(responsePaymentTerm != undefined || responsePaymentTerm != null){
        responsePaymentTerm.forEach(item => {
        result[item.term] = item.status;
      });
      }
      this.selectedterms = result

      if (act == "newdownline") {
        this.ClearDownlineForm();
        $('#showAddDownline' + id).collapse('show');
        $('#showEditDownline' + id).collapse('hide');
      }else{
       $('#showEditDownline' + id).collapse('show');
       $('#showAddDownline' + id).collapse('hide');
      }
    },
    CancelAddEditDownline(id,act) {
      if (act == "newdownline") {
        $('#showAddDownline' + id).collapse('hide');
      }else{
        $('#showEditDownline' + id).collapse('hide');
        this.GetDownlineList('created_at', 'descending')
      }
    },
  },
  components:{
    VuePhoneNumberInput,
    BaseSwitch
  }
};
</script>