<template>
    <!-- (!this.$global.charges_enabled || !this.$global.payouts_enabled) status payment di stripe -->
    <!-- (this.$global.account_requirements !== '') untuk mengetahui apakah ada error atau tidak -->
    <!-- (isAgency) apakah user_type nya userdownline -->
    <!-- (this.$global.isLoadRouterView) ketika tag dari <router-view></router-view> selesai di load -->
        <div 
            v-if="(this.$global.charges_enabled === false || this.$global.payouts_enabled === false) && (this.$global.account_requirements !== '') && (isAgency) && (this.$global.isLoadRouterView)" 
            ref="topBar"
            class="topbar bg-bar-color px-3 position-fixed rounded shadow-lg"
            :style="{'left': isMinimized ? '16rem' : '3rem'}">
                <span class="fs-3 text-bar-color">Action Required: Update your Stripe connected account. <a class="text-bar-color" :style="{textDecoration: 'underline'}" href="https://dashboard.stripe.com/account/status" target="_blank">Click here to update.</a></span>
        </div>
</template>
  
<script>

export default {
    data() {
        return {
            isAgency: '',
        }
    },
    mounted() {
        const userStorage = localStorage.getItem('userData')
        if(userStorage){
            this.isAgency = JSON.parse(localStorage.getItem('userData')).user_type === 'userdownline';
        }
    },
    computed: {
        isMinimized() {
            return this.$sidebar.isMinimized
        },
    }
};
</script>

<style scoped>
.topbar {
    width: auto !important;
    height: auto !important;
    padding: 20px !important;
    margin-top: 5px;
    right: 16px;
    left: 16rem;
    z-index: 3;
}

@media (max-width: 767.98px) {
    .topbar {
        right: 16px !important;
        left: 16px !important;;
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    .topbar {
        width: auto !important;
        right: 16px !important;
        left: 16px !important;
    }
}
</style>