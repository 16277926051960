<template>
  <div>
    <base-nav v-model="showMenu" class="navbar-absolute top-navbar justify-content-end" containerClasses='justify-content-end align-items-end d-flex flex-column justify-content-end' type="" :transparent="true">
      <ul class="navbar-nav position-relative align-items-center justify-content-end flex-row" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'" style="z-index: 4;">
        <ThemeButton class="mr-2" ></ThemeButton>
        <div id="ofBar" v-if="this.$global.globalviewmode" @click="revertViewMode">
          <div class="ofBar-txt"><i class="far fa-eye"></i></div>
        </div>
        <div class="p-2">
          <el-dropdown>
            <span class="el-dropdown-link">
              <div class="photo"><img id="topnavphoto" :src="this.$global.globalProfilePhoto" /></div><i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="width: 250px;">
              <router-link to="/user/profile-setup" v-if="this.$global.menuUserType != 'sales'">
                <el-dropdown-item :class="this.$route.path == '/user/profile-setup' && 'dropdown-active'">
                  Profile
                </el-dropdown-item>
              </router-link>
              <router-link to="/user/card-setting" v-if="(this.$global.creditcardsetup && this.$store.getters.userData.manual_bill == 'F') || (this.$global.creditcardsetup && this.$store.getters.userData.manual_bill == 'T' && this.$store.getters.userData.user_type == 'userdownline')">
                <el-dropdown-item :class="this.$route.path == '/user/card-setting' && 'dropdown-active'">
                  Card Setting
                </el-dropdown-item>
              </router-link>
              <router-link to="/user/questionnaire" v-if="this.$global.questionnairesetup && false">
                <el-dropdown-item :class="this.$route.path == '/user/questionnaire' && 'dropdown-active'">
                  Questionnaire
                </el-dropdown-item>
              </router-link>
              <router-link to="/integrations" v-if="this.$store.getters.userData.user_type === 'client'">
                <el-dropdown-item :class="this.$route.path == '/integrations' && 'dropdown-active'">
                  Integrations
                </el-dropdown-item>
              </router-link>
              <a href="#" @click="popResetPassword">
                <el-dropdown-item>
                  Change Password
                </el-dropdown-item>
              </a>
              <a href="#" @click="onOpenModalTwoFactorAuth">
                <el-dropdown-item>
                  Two Factor Authentication
                </el-dropdown-item>
              </a>
              <a href="#" v-on:click.stop.prevent="logout">
                <el-dropdown-item divided>
                  Log out
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </ul>
    </base-nav>
    <!-- Modal Update Password -->
    <modal id="modalUpdatePassword" :show.sync="modals.updatepassword" headerClasses="justify-content-center"
      modalContentClasses="modal-updatepassword">
      <h4 slot="header" class="title title-up">Change Password</h4>
      <div class="text-center">
        <img src="/img/reset-password.png" />
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="frmresetlogin" @submit.prevent="handleSubmit(ProcessResetPassword)">

          <ValidationProvider name="new password" rules="required|confirmed:confirmation"
            v-slot="{ passed, failed, errors }">
            <base-input id="newpwd" v-model="password" type="password" label="New Password" autocomplete="chrome-off"
              :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>

          <ValidationProvider name="Confirm New Password" vid="confirmation" rules="required"
            v-slot="{ passed, failed, errors }">
            <base-input id="confpass" v-model="confirmation" type="password" label="Confirm New Password"
              autocomplete="chrome-off" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
          <hr />
          <ValidationProvider name="current password" rules="required" v-slot="{ passed, failed, errors }">
            <base-input id="currpassword" v-model="currpassword" type="password" label="Your current password"
              autocomplete="chrome-off" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>

          <div class="container text-center pt-4">
            <button :disabled="isSubmittingResetPassword" type="submit" class="btn">{{ btnResetPasswordText }}</button>
          </div>
          <small v-if="errorupdatepassword"><span style="color:#ec250d">* Sorry, your current password
              invalid</span></small>
        </form>
      </ValidationObserver>
      <template slot="footer">

      </template>
    </modal>
    <!-- Modal Update Password -->

    <modal headerClasses="justify-content-center" :show.sync="modals.twofactorauth" class="modal__2fa">
      <h4 slot="header" class="title title-up">Two Factor Authentication</h4>
      <div class="text-center" style="padding-bottom: 16px;">
        <img src="/img/2fa.jpg" alt="auth" style="max-width: 250px;" />
      </div>
      <div style="display: flex; justify-content: center; padding-bottom: 16px;">
        <p class="text-center" style="font-weight: bold;">We'll now ask for a login code anytime you log in on a device we don't recognize</p>
      </div>
      <div style="display: flex; flex-direction: column; gap: 16px; align-items: center;">
        <el-card style="width: 80%; cursor: pointer; position: relative;" :class="two_factor_auth_type == 'email' ? 'two__fa__active' : ''" @click.native="onHandleSelectTwoFactorAuth('email')" :shadow="two_factor_auth_type == 'email' ? 'always' : 'hover'">
            <div class="row" style="align-items: center;">
              <div style="padding-inline: 16px;">
                <i class="fa-solid fa-at" style="font-size: xx-large;" :class="two_factor_auth_type == 'email' ? 'two__fa__icon__active' : 'text-gray'"></i>
              </div>
              <div>
                <p :style="{color: two_factor_auth_type== 'email' ? '#409eff !important' : 'gray !important'}">Email Verification</p>
              </div>
              <span v-if="two_factor_auth_type == 'email'" style="top: 0; right: 0; position: absolute; background-color: green; color: white; padding: 0px 8px; font-size: 12px;">
                Enabled
              </span>
              <span v-else style="top: 0; right: 0; position: absolute; background-color: gray; color: white; padding: 0px 8px; font-size: 12px;">
                Disabled
              </span>
          </div>
        </el-card>
        <!-- <el-card style="width: 80%; cursor: pointer;" :class="two_factor_auth_type == 'text_messaging' ? 'two__fa__active' : ''" @click.native="onHandleSelectTwoFactorAuth('text_messaging')" :shadow="two_factor_auth_type == 'text_messaging' ? 'always' : 'hover'">
            <div class="row" style="align-items: center;">
              <div style="padding-inline: 16px;">
                <i class="el-icon-message" style="font-size: xx-large;" :class="two_factor_auth_type == 'text_messaging' ? 'two__fa__icon__active' : 'text-gray'"></i>
              </div>
              <div>
                <p :style="{color: two_factor_auth_type== 'text_messaging' ? '#409eff !important' : 'gray !important'}">Text Messaging</p>
              </div>
          </div>
        </el-card> -->
        </div>
        <div style="display:  flex; justify-content: center; margin-top: 31px; margin-bottom: 16px;">
          <button :disabled="isLoadingSaveTwoFactorAuth" type="submit" class="btn" @click="onClickTwoFactorAuth">{{ isLoadingSaveTwoFactorAuth ? 'Loading....' : 'Save' }}</button>
        </div>
    </modal>
  </div>
</template>
<script>
//import { CollapseTransition } from 'vue2-transitions';
import {Dropdown, DropdownMenu, DropdownItem, Switch, Card} from 'element-ui'
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import ThemeButton from '@/components/ThemeButton';
import { extend } from "vee-validate";
import { required, confirmed, min } from "vee-validate/dist/rules";
import swal from 'sweetalert2';

extend("required", required);
extend("confirmed", confirmed);
extend("min", min);

export default {
  components: {
    SidebarToggleButton,
    //CollapseTransition,
    BaseNav,
    ThemeButton,
    Modal,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Switch.name]: Switch,
    [Card.name]: Card,
  },
  computed: {
    routeName() {
      var { name } = this.$route;
      if (this.$route.matched.length > 1) {
        name = '';
        for (let i = 0; i < this.$route.matched.length; i++) {
          if (i == (this.$route.matched.length - 1)) {
            if (this.$route.matched[i].name != 'index') {
              name = name + this.$route.matched[i].name;
            }
          } else {
            if (this.$route.matched[i + 1].name == 'index') {
              name = name + this.$route.matched[i].name;
            } else {
              name = name + this.$route.matched[i].name + ' > ';
            }
          }

        }
      }
      //const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      modals: {
        updatepassword: false,
        twofactorauth: false,
      },
      password: "",
      currpassword: "",
      confirmation: "",
      btnResetPasswordText: "Change Password",
      isSubmittingResetPassword: false,
      errorupdatepassword: false,
      two_factor_auth: false,
      two_factor_auth_type: null,
      isLoadingSaveTwoFactorAuth: false,
    };
  },
  methods: {
    revertViewMode() {
      const oriUsr = this.$global.getlocalStorage('userDataOri');
      //this.$global.SetlocalStorage('userData',oriUsr);
      localStorage.removeItem('userData');
      localStorage.removeItem('userDataOri');
      
      localStorage.setItem('userData',JSON.stringify(oriUsr));
      localStorage.removeItem('userDataOri');
      localStorage.removeItem('subdomainAgency');
      localStorage.removeItem('rootcomp');
      this.$store.dispatch('setUserData', {
              user: oriUsr,
      });
      window.document.location = "/configuration/agency-list/";
    },
    popupdatepasswordsuccess() {
      swal.fire({
        title: 'Change Password',
        text: 'your password has been updated!',
        timer: 2000,
        showConfirmButton: false,
        icon: 'success'
      });
    },
    ProcessResetPassword() {
      this.btnResetPasswordText = 'Change new password...';
      this.isSubmittingResetPassword = true;

      var userdata = this.$store.getters.userData

      this.$store.dispatch('updatePass', {
        usrID: userdata.id,
        newpassword: this.password,
        currpassword: this.currpassword,
      })
        .then(response => {
          if (response.result == 'success') {
            this.modals.updatepassword = false;
            this.popupdatepasswordsuccess();
          } else {
            this.btnResetPasswordText = "Reset Password";
            this.isSubmittingResetPassword = false;
            this.errorupdatepassword = true;
          }

        }, error => {
          this.btnResetPasswordText = "Reset Password";
          this.isSubmittingResetPassword = false;
          this.errorupdatepassword = true;

        })

    },
    popResetPassword() {

      this.password = "";
      this.confirmation = "";
      this.btnResetPasswordText = "Change Password";
      this.isSubmittingResetPassword = false;
      this.errorupdatepassword = false;
      this.modals.updatepassword = true;
    },
    logout() {
      localStorage.removeItem('companyGroupSelected');
      localStorage.removeItem('subdomainAgency');
      localStorage.removeItem('rootcomp');
      this.$global.selectsGroupCompany.companyGroupList = null;
      this.$store.dispatch('destroyToken')
        .then(response => {
          //this.$router.push({ name: 'Login' })
          window.document.location = '/login';
        })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async onOpenModalTwoFactorAuth(){
      const userData = this.$store.getters.userData
      await this.$store.dispatch('getSettingTwoFactorAuth', {
        userId: userData.id,
      }).then(response => {
        this.two_factor_auth = response.two_factor_auth
        this.two_factor_auth_type = response.two_factor_auth_type
        this.modals.twofactorauth = true;
      }, error => {
        this.$notify({
          type: 'primary',
          message: error.message,
          icon: 'fas fa-bug'
        })
      })

    },
    onCloseModalTwoFactorAuth(){
      this.two_factor_auth = false
      this.two_factor_auth_type = null
      this.modals.twofactorauth = false;
    },
    async onClickTwoFactorAuth(){
      this.isLoadingSaveTwoFactorAuth = true
      const userData = this.$store.getters.userData

      const payload = {
        userId: userData.id,
        two_factor_auth: this.two_factor_auth,
        two_factor_auth_type: this.two_factor_auth_type,
      }

      await this.$store.dispatch('settingTwoFactorAuth', payload)
      .then(response => {
        this.$notify({
            type: 'success',
            message: response.message,
            icon: 'far fa-save'
        });
      }, error => {
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'fa fa-save'
        })
      })
      this.isLoadingSaveTwoFactorAuth = false
      this.onCloseModalTwoFactorAuth()
    },
    onHandleSelectTwoFactorAuth(value){
      if(this.two_factor_auth_type == value){
        this.two_factor_auth = false,
        this.two_factor_auth_type = null
      } else {
        this.two_factor_auth = true
        this.two_factor_auth_type = value
      }
    },
  },
  mounted() {
    /*var userdata = this.$store.getters.userData
    if (userdata.customer_card_id != '') {
      this.$global.creditcardsetup = true;
    }else{
      this.$global.creditcardsetup = false;
    }*/
  }
};
</script>
<style>
.top-navbar {
  top: 0px;
}
.modal-content .form-group:not(.has-danger) input{
  border: 1px solid #cad1d7 !important;
}
.modal .modal-body .form-group input{
  color:#222a42;
}

.modal-updatepassword {
  max-width: 520px;
  margin: 0 auto;
}

.el-dropdown-link > i {
  color: #fff;
  transition: 0.3s;
}

.el-dropdown-link:hover i {
  color: var(--btn-primary-color);
  transition: 0.3s;
}

.el-dropdown-menu__item:hover {
  background-color: var(--btn-primary-color) !important;
  color: #fff !important;
  transition: 0.3s;
}

.dropdown-active {
  background-color: var(--btn-primary-color) !important;
  color: #fff !important;
}

.modal__2fa .modal-dialog .modal-content {
  max-width: 520px;
  margin: auto;
}

.two__fa__active {
  border: 1px solid #409eff;
}

.two__fa__icon__active {
  color: #409eff;
}

.text-gray {
  color: gray;
}
</style>
